<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar mr-2">
          <i class="fas fa-user"></i>
        </div>
        <span>{{username}}</span>
        <i class="fas fa-caret-down fa-sm ml-2"></i>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>บัญชีผู้ใช้</strong>
    </CDropdownHeader>
    <CDropdownItem @click="isChangeProfile = !isChangeProfile">
      <CIcon name="cil-user" /> แก้ไขข้อมูลส่วนตัว
    </CDropdownItem>
    <CDropdownItem @click="isChangePassword = !isChangePassword">
      <CIcon name="cil-settings" /> เปลี่ยนรหัสผ่าน
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> ออกจากระบบ
    </CDropdownItem>
    <ChangePasswordModal :is-show="isChangePassword" @close="isChangePassword = false" />
    <EditProfileModal :is-show="isChangeProfile" @close="isChangeProfile = false" />
  </CDropdown>
</template>

<script>
import AppConfig from '@/configs/app.config.js'
import Auth from '@/helpers/auth'
import ChangePasswordModal from '@/views/components/ChangePasswordModal'
import EditProfileModal from '@/views/components/EditProfileModal'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: {
    ChangePasswordModal,
    EditProfileModal
  },
  data () {
    return { 
      AccountInterval: null,
      isChangePassword: false,
      isChangeProfile: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    username() {

      const firstName = this.UserInfo?.firstName
      if(firstName) {
        return `${this.UserInfo?.username} (${firstName})`
      }else{
        return `${this.UserInfo?.username}`
      }
    }
  },
  methods: {
    logout() {
      console.log('logout!')
      Auth.logout()
    }
  },
  mounted() {
    this.$store.dispatch('reloadAccount')

    this.AccountInterval = setInterval(()=>{
      console.log('reload account')
      const isLoggedIn = localStorage.getItem(AppConfig.key.LoggedIn)
      console.log('isLoggedIn', isLoggedIn)
      if(isLoggedIn) {
        this.$store.dispatch('reloadAccount')
      }else{
        clearInterval(this.AccountInterval)
      }
    }, 120000)
  },
  beforeDestroy() {
    clearInterval(this.AccountInterval)
  }
}
</script>

<style lang="scss" scoped>
.c-avatar {
  background-color: #52CF8E;

  .fa-user {
    color: #028F44;
  }
}
</style>
