<template>
  <CFooter :fixed="false" style="background-color: #028F44; color: #FFFFFF;">
    <div class="container text-center">
      <span class="ml-1">&copy;{{new Date().getFullYear()}} Masterlot88</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
